import { useEffect, useState } from "react";
import LadningPage from "./LandingPage/LadningPage";
import Menu from "./Menu/Menu";
import Shop from "./Shop/Shop";
import AdminPanel from "./AdminPanel/AdminPanel";
import LoginPage from "./LoginPage/LoginPage";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import * as S from "./App.styles";

function App() {
  const today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const lastShown = localStorage.getItem(
    "gbAquariumWelcomeMessageLastShownDate"
  );
  const link = window.location.href;
  const [isAdmin, setIsAdmin] = useState(false);
  const pages = ["Sklep", isAdmin ? "Panel Administratora" : ""].filter(
    (x) => x !== ""
  );
  const [selectedPage, setSelectedPage] = useState("LandingPage");
  const [isInitialState, setIsInitialState] = useState(
    !link.includes("informacja") && lastShown !== today
  );

  useEffect(() => {
    if (isInitialState) {
      localStorage.setItem("gbAquariumWelcomeMessageLastShownDate", today);
    }
  }, [isInitialState]);

  useEffect(() => {
    if (window.location.href.includes("adminPanelGB") && !isAdmin) {
      setSelectedPage("AdminPage");
    }
  }, []);

  return (
    <div>
      {isInitialState ? (
        <>
          <S.ModalOverlay onClick={() => setIsInitialState(false)} />
          <S.InfomrationModal>
            <S.CloseIcon onClick={() => setIsInitialState(false)}>
              <S.Close src={`${process.env.PUBLIC_URL}/assets/closeIcon.svg`} />
            </S.CloseIcon>
            <p>
              <strong>Uwaga!</strong>
              <br />
              Po więcej informacji zapraszam do kontaktu telefonicznego
              698954898. <br /> Nie wysyłamy ryb - odbiór jedynie osobiście po
              umówionej wizycie telefonicznie. <br />
            </p>
          </S.InfomrationModal>
        </>
      ) : null}
      {selectedPage === "AdminPage" && !isAdmin ? (
        <LoginPage changeAdminValue={setIsAdmin} changePage={setSelectedPage} />
      ) : (
        <>
          <Menu
            listOfTabs={pages}
            onClickHandler={(selectedOption) => {
              navigate("/sklep");
              setSelectedPage(selectedOption);
            }}
          />
          <Routes>
            <Route path="/" element={<LadningPage />} />
            <Route path="/informacja/:id" element={<LadningPage />} />
            <Route path="/sklep" element={<Shop />} />
            <Route path="/sklep/:categoryName" element={<Shop />} />
            <Route path="/sklep/:categoryName/:id" element={<Shop />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
